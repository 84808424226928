const LoanData: object = [
  {
    selectStatusCitizen: [
      {
        selectValCitizen: "01",
        selectLabelCitizen: "Монгол улсын иргэн"
      },
      {
        selectValCitizen: "02",
        selectLabelCitizen: "Гадааддын иргэн"
      }
    ],
    selectFitchRating: [
      {
        selectFitchRatingVal: "01",
        selectFitchRatingLabel: "A1+ AAA"
      },
      {
        selectFitchRatingVal: "02",
        selectFitchRatingLabel: "A1+ AA+"
      },
      {
        selectFitchRatingVal: "03",
        selectFitchRatingLabel: "A1+ AA"
      },
      {
        selectFitchRatingVal: "04",
        selectFitchRatingLabel: "A1+ AA-"
      },
      {
        selectFitchRatingVal: "05",
        selectFitchRatingLabel: "A1 A+"
      },
      {
        selectFitchRatingVal: "06",
        selectFitchRatingLabel: "A1 A"
      },
      {
        selectFitchRatingVal: "07",
        selectFitchRatingLabel: "A2 A-"
      },
      {
        selectFitchRatingVal: "08",
        selectFitchRatingLabel: "A2 BBB+"
      },
      {
        selectFitchRatingVal: "09",
        selectFitchRatingLabel: "A3 BBB"
      },
      {
        selectFitchRatingVal: "10",
        selectFitchRatingLabel: "A3 BBB-"
      },
      {
        selectFitchRatingVal: "11",
        selectFitchRatingLabel: "B BB+"
      },
      {
        selectFitchRatingVal: "12",
        selectFitchRatingLabel: "B BB"
      },
      {
        selectFitchRatingVal: "13",
        selectFitchRatingLabel: "B BB-"
      },
      {
        selectFitchRatingVal: "14",
        selectFitchRatingLabel: "B B+"
      },
      {
        selectFitchRatingVal: "15",
        selectFitchRatingLabel: "B B"
      },
      {
        selectFitchRatingVal: "16",
        selectFitchRatingLabel: "B B-"
      },
      {
        selectFitchRatingVal: "17",
        selectFitchRatingLabel: "C CCC"
      },
      {
        selectFitchRatingVal: "18",
        selectFitchRatingLabel: "/ DDD"
      },
      {
        selectFitchRatingVal: "19",
        selectFitchRatingLabel: "/ DD"
      },
      {
        selectFitchRatingVal: "20",
        selectFitchRatingLabel: "/ D"
      }
    ],
    selectSandpRating: [
      {
        selectSandpRatingVal: "01",
        selectSandpRatingLabel: "A-1+ AAA"
      },
      {
        selectSandpRatingVal: "02",
        selectSandpRatingLabel: "A-1+ AA+"
      },
      {
        selectSandpRatingVal: "03",
        selectSandpRatingLabel: "A-1+ AA"
      },
      {
        selectSandpRatingVal: "04",
        selectSandpRatingLabel: "A-1+ AA-"
      },
      {
        selectSandpRatingVal: "05",
        selectSandpRatingLabel: "A-1 A+"
      },
      {
        selectSandpRatingVal: "06",
        selectSandpRatingLabel: "A-1 A"
      },
      {
        selectSandpRatingVal: "07",
        selectSandpRatingLabel: "A-2 A-"
      },
      {
        selectSandpRatingVal: "08",
        selectSandpRatingLabel: "A-2 BBB+"
      },
      {
        selectSandpRatingVal: "09",
        selectSandpRatingLabel: "A-3 BBB"
      },
      {
        selectSandpRatingVal: "10",
        selectSandpRatingLabel: "A-3 BBB-"
      },
      {
        selectSandpRatingVal: "11",
        selectSandpRatingLabel: "B BB+"
      },
      {
        selectSandpRatingVal: "12",
        selectSandpRatingLabel: "B BB"
      },
      {
        selectSandpRatingVal: "13",
        selectSandpRatingLabel: "B BB-"
      },
      {
        selectSandpRatingVal: "14",
        selectSandpRatingLabel: "B B+"
      },
      {
        selectSandpRatingVal: "15",
        selectSandpRatingLabel: "B B"
      },
      {
        selectSandpRatingVal: "16",
        selectSandpRatingLabel: "B B-"
      },
      {
        selectSandpRatingVal: "17",
        selectSandpRatingLabel: "C CCC"
      },
      {
        selectSandpRatingVal: "18",
        selectSandpRatingLabel: "/ DDD"
      },
      {
        selectSandpRatingVal: "19",
        selectSandpRatingLabel: "/ DD"
      },
      {
        selectSandpRatingVal: "20",
        selectSandpRatingLabel: "/ D"
      }
    ],
    selectMoodysRating: [
      {
        selectMoodysRatingVal: "01",
        selectMoodysRatingLabel: "P-1 Aaa"
      },
      {
        selectMoodysRatingVal: "02",
        selectMoodysRatingLabel: "P-1 Aa1"
      },
      {
        selectMoodysRatingVal: "03",
        selectMoodysRatingLabel: "P-1 Aa2"
      },
      {
        selectMoodysRatingVal: "04",
        selectMoodysRatingLabel: "P-1 Aa3"
      },
      {
        selectMoodysRatingVal: "05",
        selectMoodysRatingLabel: "P-1 A1"
      },
      {
        selectMoodysRatingVal: "06",
        selectMoodysRatingLabel: "P-1 A2"
      },
      {
        selectMoodysRatingVal: "07",
        selectMoodysRatingLabel: "P-2 A3"
      },
      {
        selectMoodysRatingVal: "08",
        selectMoodysRatingLabel: "P-2 Baa1"
      },
      {
        selectMoodysRatingVal: "09",
        selectMoodysRatingLabel: "P-3 Baa2"
      },
      {
        selectMoodysRatingVal: "10",
        selectMoodysRatingLabel: "P-3 Baa3"
      },
      {
        selectMoodysRatingVal: "11",
        selectMoodysRatingLabel: "Ba1"
      },
      {
        selectMoodysRatingVal: "12",
        selectMoodysRatingLabel: "Ba2"
      },
      {
        selectMoodysRatingVal: "13",
        selectMoodysRatingLabel: "Ba3"
      },
      {
        selectMoodysRatingVal: "14",
        selectMoodysRatingLabel: "B1"
      },
      {
        selectMoodysRatingVal: "15",
        selectMoodysRatingLabel: "B2"
      },
      {
        selectMoodysRatingVal: "16",
        selectMoodysRatingLabel: "B3"
      },
      {
        selectMoodysRatingVal: "17",
        selectMoodysRatingLabel: "Caa"
      },
      {
        selectMoodysRatingVal: "18",
        selectMoodysRatingLabel: "Ca"
      },
      {
        selectMoodysRatingVal: "19",
        selectMoodysRatingLabel: "C"
      },
      {
        selectMoodysRatingVal: "20",
        selectMoodysRatingLabel: "/"
      }
    ],
    selectCompanyTypeCode: [
      {
        selectCompanyTypeCodeVal: "01",
        selectCompanyTypeCodeLabel: "ХК"
      },
      {
        selectCompanyTypeCodeVal: "02",
        selectCompanyTypeCodeLabel: "ХХК"
      },
      {
        selectCompanyTypeCodeVal: "03",
        selectCompanyTypeCodeLabel: "ХЗХ"
      },
      {
        selectCompanyTypeCodeVal: "04",
        selectCompanyTypeCodeLabel: "Хоршоо"
      },
      {
        selectCompanyTypeCodeVal: "05",
        selectCompanyTypeCodeLabel: "ТББ"
      },
      {
        selectCompanyTypeCodeVal: "06",
        selectCompanyTypeCodeLabel: "ОНБ"
      },
      {
        selectCompanyTypeCodeVal: "07",
        selectCompanyTypeCodeLabel: "Сан"
      },
      {
        selectCompanyTypeCodeVal: "08",
        selectCompanyTypeCodeLabel: "Бусад"
      }
    ],
    selectStatusOrg: [
      {
        selectValOrg: "03",
        selectLabelOrg: "Бизнесийн байгууллага"
      },
      {
        selectValOrg: "04",
        selectLabelOrg: "Төрийн байгууллага"
      },
      {
        selectValOrg: "05",
        selectLabelOrg: "Сүм хийд"
      },
      {
        selectValOrg: "06",
        selectLabelOrg: "Сан"
      }
    ],
    selectloancharttype: [
      {
        selectloancharttypeval: "01",
        selectloancharttypelabel: "Жилээр"
      },
      {
        selectloancharttypeval: "02",
        selectloancharttypelabel: "Хагас жилээр"
      },
      {
        selectloancharttypeval: "03",
        selectloancharttypelabel: "Улирлаар"
      },
      {
        selectloancharttypeval: "04",
        selectloancharttypelabel: "Сараар"
      },
      {
        selectloancharttypeval: "05",
        selectloancharttypelabel: "Хувь тэнцүүлсэн"
      },
      {
        selectloancharttypeval: "06",
        selectloancharttypelabel: "Tэгш бус"
      }
    ],
    statusType: [
      {
        selectVal: "07",
        selectLabel: "Бүгд"
      },
      {
        selectVal: "03",
        selectLabel: "Банк"
      },
      {
        selectVal: "04",
        selectLabel: "ББСБ"
      },
      {
        selectVal: "05",
        selectLabel: "ХЗХ"
      },
      {
        selectVal: "06",
        selectLabel: "Бусад"
      }
    ],
    selectLoanPurpose1: [
      {
        selectLoanPurpose1Val: "I",
        selectLoanPurpose1Label:
          "Байр, сууц болон хоол хүнсээр үйлчлэх үйл ажиллагаа "
      },
      {
        selectLoanPurpose1Val: "F",
        selectLoanPurpose1Label: "Барилга"
      },
      {
        selectLoanPurpose1Val: "P",
        selectLoanPurpose1Label: "Боловсрол"
      },
      {
        selectLoanPurpose1Val: "C",
        selectLoanPurpose1Label: "Боловсруулах үйлдвэрлэл"
      },
      {
        selectLoanPurpose1Val: "G",
        selectLoanPurpose1Label:
          "Бөөний болон жижиглэн худалдаа; машин, мотоциклийн засвар үйлчилгээ"
      },
      {
        selectLoanPurpose1Val: "N",
        selectLoanPurpose1Label:
          "Захиргааны болон дэмжлэг үзүүлэх үйл ажиллагаа"
      },
      {
        selectLoanPurpose1Val: "J",
        selectLoanPurpose1Label: "Мэдээлэл, холбоо"
      },
      {
        selectLoanPurpose1Val: "M",
        selectLoanPurpose1Label:
          "Мэргэжлийн, шинжлэх ухаан болон техникийн үйл ажиллагаа"
      },
      {
        selectLoanPurpose1Val: "U",
        selectLoanPurpose1Label:
          "Олон улсын байгууллага, суурин төлөөлөгчийн үйл ажиллагаа"
      },
      {
        selectLoanPurpose1Val: "K",
        selectLoanPurpose1Label: "Санхүүгийн болон даатгалын үйл ажиллагаа"
      },
      {
        selectLoanPurpose1Val: "H",
        selectLoanPurpose1Label: "Тээвэр ба агуулахын үйл ажиллагаа"
      },
      {
        selectLoanPurpose1Val: "O",
        selectLoanPurpose1Label:
          "Төрийн удирдлага ба батлан хамгаалах үйл ажиллагаа, албан журмын нийгмийн хамгаалал"
      },
      {
        selectLoanPurpose1Val: "R",
        selectLoanPurpose1Label: "Урлаг, үзвэр, тоглоом наадам"
      },
      {
        selectLoanPurpose1Val: "E",
        selectLoanPurpose1Label:
          "Усан хангамж; бохир ус, хог, хаягдлын менежмент болон цэвэрлэх үйл ажиллагаа"
      },
      {
        selectLoanPurpose1Val: "B",
        selectLoanPurpose1Label: "Уул уурхай, олборлолт"
      },
      {
        selectLoanPurpose1Val: "24",
        selectLoanPurpose1Label: "Хэрэглээний зээл"
      },
      {
        selectLoanPurpose1Val: "T",
        selectLoanPurpose1Label:
          "Хүн хөлслөн ажиллуулдаг өрхийн үйл ажиллагаа, өрхийн өөрийн хэрэглээнд үйлдвэрлэсэн, хэмжээг нь тодорхойгүй бүтээгдэхүүн, үйлчилгээ"
      },
      {
        selectLoanPurpose1Val: "Q",
        selectLoanPurpose1Label: "Хүний эрүүл мэнд ба нийгмийн үйл ажиллагаа"
      },
      {
        selectLoanPurpose1Val: "A",
        selectLoanPurpose1Label:
          "Хөдөө аж ахуй, ойн аж ахуй, загас барилт, ан агнуур"
      },
      {
        selectLoanPurpose1Val: "D",
        selectLoanPurpose1Label: "Цахилгаан, хий, уур, агааржуулалтын хангамж"
      },
      {
        selectLoanPurpose1Val: "S",
        selectLoanPurpose1Label: "Үйлчилгээний бусад үйл ажиллагаа"
      },
      {
        selectLoanPurpose1Val: "L",
        selectLoanPurpose1Label: "Үл хөдлөх хөрөнгийн үйл ажиллагаа"
      },
      {
        selectLoanPurpose1Val: "**",
        selectLoanPurpose1Label: "Өмнөх системд тодорхой биш"
      }
    ],
    selectLoanClass: [
      {
        selectLoanClassVal: "01",
        selectLoanClassLabel: "Хэвийн"
      },
      {
        selectLoanClassVal: "02",
        selectLoanClassLabel: "Анхаарал хандуулах"
      },
      {
        selectLoanClassVal: "03",
        selectLoanClassLabel: "Хэвийн бус"
      },
      {
        selectLoanClassVal: "04",
        selectLoanClassLabel: "Эргэлзээтэй"
      },
      {
        selectLoanClassVal: "05",
        selectLoanClassLabel: "Муу"
      }
    ],
    selectLoanSource: [
      {
        selectLoanSourceVal: "01",
        selectLoanSourceLabel: "Зээл авахаар өргөдөл гаргасан"
      },
      {
        selectLoanSourceVal: "02",
        selectLoanSourceLabel: "Зээлийн шугамын дагуу олгогдсон"
      },
      {
        selectLoanSourceVal: "03",
        selectLoanSourceLabel: "Батлан даалтаас шилжиж ирсэн"
      },
      {
        selectLoanSourceVal: "04",
        selectLoanSourceLabel: "Аккредитиваас шилжиж ирсэн"
      },
      {
        selectLoanSourceVal: "05",
        selectLoanSourceLabel: "Бусад хүлээсэн үүргээс шилжиж ирсэн"
      },
      {
        selectLoanSourceVal: "06",
        selectLoanSourceLabel: "Бусад"
      }
    ],
    selectCurrency: [
      {
        selectCurrencyVal: "ZZZ",
        selectCurrencyLabel: "ZZZ"
      },
      {
        selectCurrencyVal: "AUD",
        selectCurrencyLabel: "Австрали доллар"
      },
      {
        selectCurrencyVal: "ATS",
        selectCurrencyLabel: "Австри шиллинг"
      },
      {
        selectCurrencyVal: "USD",
        selectCurrencyLabel: "АНУ-ын доллар"
      },
      {
        selectCurrencyVal: "BEF",
        selectCurrencyLabel: "Белги франк"
      },
      {
        selectCurrencyVal: "BGL",
        selectCurrencyLabel: "Болгари лев"
      },
      {
        selectCurrencyVal: "VND",
        selectCurrencyLabel: "Вейтьнам донг"
      },
      {
        selectCurrencyVal: "DEM",
        selectCurrencyLabel: "Герман марк"
      },
      {
        selectCurrencyVal: "DKK",
        selectCurrencyLabel: "Дани крон"
      },
      {
        selectCurrencyVal: "EUR",
        selectCurrencyLabel: "Евро"
      },
      {
        selectCurrencyVal: "EGP",
        selectCurrencyLabel: "Египт фунт"
      },
      {
        selectCurrencyVal: "IDR",
        selectCurrencyLabel: "Индонеэи рупи"
      },
      {
        selectCurrencyVal: "ITL",
        selectCurrencyLabel: "Итали лир"
      },
      {
        selectCurrencyVal: "GBP",
        selectCurrencyLabel: "Их британийн фунт стерлинг"
      },
      {
        selectCurrencyVal: "KZT",
        selectCurrencyLabel: "Казакстан тэнгэ"
      },
      {
        selectCurrencyVal: "CAD",
        selectCurrencyLabel: "Канад доллар"
      },
      {
        selectCurrencyVal: "KWD",
        selectCurrencyLabel: "Кувейт динар"
      },
      {
        selectCurrencyVal: "LAK",
        selectCurrencyLabel: "Лаос киф"
      },
      {
        selectCurrencyVal: "MYR",
        selectCurrencyLabel: "Малайз рингитт"
      },
      {
        selectCurrencyVal: "MNT",
        selectCurrencyLabel: "Монгол төгрөг"
      },
      {
        selectCurrencyVal: "RUB",
        selectCurrencyLabel: "ОХУ-ын рубль"
      },
      {
        selectCurrencyVal: "SGD",
        selectCurrencyLabel: "Сингапур доллар"
      },
      {
        selectCurrencyVal: "KPW",
        selectCurrencyLabel: "Солонгос вон"
      },
      {
        selectCurrencyVal: "KRW",
        selectCurrencyLabel: "Солонгосын вон"
      },
      {
        selectCurrencyVal: "TWD",
        selectCurrencyLabel: "Тайван доллар"
      },
      {
        selectCurrencyVal: "THB",
        selectCurrencyLabel: "Тайланд бат"
      },
      {
        selectCurrencyVal: "TRL",
        selectCurrencyLabel: "Турк шинэ лир"
      },
      {
        selectCurrencyVal: "HUF",
        selectCurrencyLabel: "Унгар форинт"
      },
      {
        selectCurrencyVal: "FIM",
        selectCurrencyLabel: "Финлянд марк"
      },
      {
        selectCurrencyVal: "FRF",
        selectCurrencyLabel: "Франц франк"
      },
      {
        selectCurrencyVal: "HKD",
        selectCurrencyLabel: "Хонконг доллар"
      },
      {
        selectCurrencyVal: "CNY",
        selectCurrencyLabel: "Хятадын юань"
      },
      {
        selectCurrencyVal: "CZK",
        selectCurrencyLabel: "Чех крон"
      },
      {
        selectCurrencyVal: "SEK",
        selectCurrencyLabel: "Швед крон"
      },
      {
        selectCurrencyVal: "CHF",
        selectCurrencyLabel: "Швейцарь франк"
      },
      {
        selectCurrencyVal: "INR",
        selectCurrencyLabel: "Энэтхэг рупи"
      },
      {
        selectCurrencyVal: "JPY",
        selectCurrencyLabel: "Японы иен"
      }
    ],
    selectLoanType: [
      {
        selectLoanTypeVal: "01",
        selectLoanTypeLabel: "Зээл"
      },
      {
        selectLoanTypeVal: "02",
        selectLoanTypeLabel: "Батлан даалт"
      },
      {
        selectLoanTypeVal: "03",
        selectLoanTypeLabel: "Аккредитив"
      },
      {
        selectLoanTypeVal: "04",
        selectLoanTypeLabel: "Түрээс"
      },
      {
        selectLoanTypeVal: "05",
        selectLoanTypeLabel: "Санхүүгийн үүрэг"
      },
      {
        selectLoanTypeVal: "06",
        selectLoanTypeLabel: "Авлага"
      },
      {
        selectLoanTypeVal: "07",
        selectLoanTypeLabel: "Зээлийн шугам"
      },
      {
        selectLoanTypeVal: "08",
        selectLoanTypeLabel: "Үнэт цаас"
      }
    ],
    selectOccupationCitizen: [
      {
        selectValOccupation: "01",
        selectLabelOccupation: "Сувилагч"
      },
      {
        selectValOccupation: "02",
        selectLabelOccupation: "Барилгачин"
      },
      {
        selectValOccupation: "03",
        selectLabelOccupation: "Цахилгаанчин"
      },
      {
        selectValOccupation: "04",
        selectLabelOccupation: "Гагнуурчин"
      },
      {
        selectValOccupation: "05",
        selectLabelOccupation: "Багш"
      },
      {
        selectValOccupation: "06",
        selectLabelOccupation: "Бизнесмэн"
      },
      {
        selectValOccupation: "07",
        selectLabelOccupation: "Дизайнер"
      },
      {
        selectValOccupation: "08",
        selectLabelOccupation: "Жүжигчин"
      },
      {
        selectValOccupation: "09",
        selectLabelOccupation: "Загвар"
      },
      {
        selectValOccupation: "10",
        selectLabelOccupation: "Эдийн засагч"
      },
      {
        selectValOccupation: "11",
        selectLabelOccupation: "Менежер"
      },
      {
        selectValOccupation: "12",
        selectLabelOccupation: "Ня-бо"
      },
      {
        selectValOccupation: "13",
        selectLabelOccupation: "Программист"
      },
      {
        selectValOccupation: "14",
        selectLabelOccupation: "Судлаач"
      },
      {
        selectValOccupation: "15",
        selectLabelOccupation: "Сэтгүүлч"
      },
      {
        selectValOccupation: "16",
        selectLabelOccupation: "Тамирчин"
      },
      {
        selectValOccupation: "17",
        selectLabelOccupation: "Түүхч"
      },
      {
        selectValOccupation: "18",
        selectLabelOccupation: "Бусад"
      }
    ]
  }
];

export default LoanData;
