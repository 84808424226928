
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import InquiryReferenceData from "@/data/payment";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import LoanData from "@/data/loan";
import { saveFile } from "@/core/services/FileSaverService";
import store from "@/store";

export default defineComponent({
  name: "inquiryReference",
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Лавлагааний тайлан");
    });
    const isu = store.getters.getMbUser as boolean;

    return {
      isu
    };
  },
  data() {
    return {
      page: 1,
      pageSize: 10,
      dataLoading: true,
      loadingButton: "off",
      searchValue: "",
      yearValue: new Date().getFullYear().toString(),
      year: InquiryReferenceData[0]["year"],
      monthValue:
        (new Date().getMonth() + 1).toString().length === 2
          ? (new Date().getMonth() + 1).toString()
          : "0" + (new Date().getMonth() + 1).toString(),
      months: InquiryReferenceData[0]["months"],
      tableData: [],
      orgList: [],
      selectOrgVal: "*",
      statusType: LoanData[0]["statusType"],
      selectVal: "07"
    };
  },
  computed: {
    filteredOrgList(): any {
      return this.orgList.filter((data: any) => {
        return (
          !this.selectVal ||
          data.selectOrgType.includes(this.selectVal.toLowerCase())
        );
      });
    }
  },
  mounted() {
    if (this.isu) {
      this.getOrg();
    }
    this.getInqRef(this.yearValue, this.monthValue, this.selectOrgVal);
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
    },
    handleChange(value) {
      this.pageSize = value;
    },
    async getInqRef(y, m, ocode) {
      this.dataLoading = true;
      const slug =
        "?yearMonth=" + y + m + (ocode === "*" ? "" : "&org=" + ocode);
      await ApiService.get(`inquiryreference`, slug)
        .then(res => {
          const results = res.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error",
              iconClass: "el-text-error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else {
            this.tableData = results.response;
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    async getOrg() {
      this.dataLoading = true;
      await ApiService.get(`org`)
        .then(resp => {
          const results = resp.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error",
              iconClass: "el-text-error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else {
            this.orgList = results.response.map(org => ({
              selectOrgVal: org.orgcode,
              selectOrgType: org.orgtypecode,
              selectOrgLabel: org.orgname
            }));
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    async getReport(type, username) {
      document.body.classList.add("waiting");
      this.loadingButton = "on";
      const params = {
        type: type,
        username: username,
        date: this.yearValue + this.monthValue,
        orgcode: this.selectOrgVal
      };
      await ApiService.post(`inquiryreference`, { params })
        .then(resp => {
          const results = resp.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning"
            });
          } else {
            if (type === "inquiryHistoryByUser")
              saveFile(
                results.response,
                username,
                "application/vnd.ms-excel",
                ".xls"
              );
            else
              saveFile(
                results.response,
                type,
                "application/vnd.ms-excel",
                ".xls"
              );
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Тайлан татхад алдаа гарлаа.",
            type: "error"
          });
        })
        .finally(() => {
          document.body.classList.remove("waiting");
          this.loadingButton = "off";
        });
    }
  }
});
